
import { defineComponent } from "vue";

export default defineComponent({
  name: "CategoriesLoader",
  setup () {
    const types = {
      categories: "button@3",
      toggleRow: "text",
    };

    return { types };
  },
});
